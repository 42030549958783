.app {
	min-height: 100vh; // calc(100vh - 33px); // 33px for dev debug bar
	padding-bottom: 75px;
	position: relative;

	width: 95%;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;


	.app-body {
		position: relative;

		.loading-lock {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0;
			background: white;
			opacity: 0;
			z-index: 1000;
			transition: opacity 300ms ease-in-out;
			overflow: hidden;

			img {
				position: fixed;
				left: 50%;
				top: -50%;
				transform: translate(-50%, -50%);
				transition: all 200ms ease-in-out;
				opacity: 0;
			}

			&.is-loading {
				opacity: 0.7;
				height: 100%;

				img {
					opacity: 1;
					top: 50%;
				}
			}
		}
	}
}


.search {
	margin-bottom: 20px;
}

.categories {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@media all and (max-width: 1275px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media all and (max-width: 935px) {
		grid-template-columns: 1fr 1fr;
	}

	@media all and (max-width: 610px) {
		grid-template-columns: 1fr;
		max-width: 400px;
		margin: 0 auto;
		row-gap: 30px;
	}

	.category-link {
		text-align: center;
		border: 1px solid $gray-light;
		background: $green;
		transition: all 300ms ease-in-out;

		figure, h2 {
			margin: 0;
		}

		figure {
			// min-height: 100px;
			background: white;
		}

		.category-title {
			transition: all 300ms ease-in-out;
		}

		&:hover {
			background: darken($green, 10%);

			.category-title {
				background: darken($green, 10%);
			}
		}
	}

	.category-title {
		width: 100%;
		font-size: 1.4rem;
		padding: 10px 5px;
		color: white;
		background: $green;
		font-family: $futura;
	}
}

.cart-actions {
	padding: 30px 15px 15px 15px;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
}


.filters,
.product-details,
.product-row {
	width: 100%;
	border: 1px solid $gray-light;
	padding: 15px;

	display: flex;

	.product {
		flex-grow: 1;
		display: grid;
		grid-template-columns: 1fr 400px;
		column-gap: 20px;

		table {
			max-width: 800px;
		}
	}

	.product-image-zoom {
		position: absolute;
		padding: 5px;

		svg {
			width: 35px;
			height: 35px;
			fill: white;
		}
	}

	.filters-header,
	.product-header {
		display: grid;
		grid-template-columns: 1fr 270px;
		padding-bottom: 10px;

		h2 {
			display: inline-block;
			font-size: 1.4rem;
			padding: 0;
			margin: 0;
		}
	}

	.filters-header {
		width: 100%;
		grid-template-columns: 200px 1fr;
		border-bottom: 1px solid $gray-light;
		margin-bottom: 10px;

		.active-filters {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;

			li {
				// padding: 5px 10px;
			}
		}
	}

	.more-filters {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		padding-bottom: 10px;
		border-bottom: 1px solid $gray-light;
	}

	.secondary-filters {
		width: 100%;
		height: 0;
		overflow: hidden;
		transition: all 400ms ease-in-out;

		.filters-ui {
			padding: 30px 0;
		}
	}

	.product-info {
		width: 100%;

		&.full {
			grid-column-start: 1;
			grid-column-end: 3;
		}

		p {
			display: block;
		}
	}

	.product-images {
		figure {
			position: relative;
			margin-bottom: 0;

			img {
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}

	.filters-actions {
		display: flex;
		justify-content: flex-start;
		column-gap: 20px;

		.active-filters {
			display: flex;

			.filter-group {
				padding: 0 20px;
				margin: 0;
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				row-gap: 8px;
				column-gap: 10px;

				li {
					& > label {
						display: block;
						margin-bottom: 20px;
					}
				}
			}

			.btn {
				position: relative;
				padding-right: 40px;

				&:after {
					content: '\00D7';
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translateY(-50%);
					font-size: 1.4em;
				}
			}
		}
	}

	.product-actions {
		display: flex;
		justify-content: space-between;

		align-self: start;
	}


	@media all and (max-width: 1100px) {
		.product {
			grid-template-columns: 1fr 300px;
		}

		.filters-header,
		.product-header {
			grid-template-columns: 1fr;
			grid-row-gap: 10px;

			h2 {
				grid-row-start: 2;
			}

			.filters-actions,
			.product-actions {
				grid-row-start: 1;
				justify-content: flex-start;

				& > .btn,
				.inp-btn {
					margin-right: 10px;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.product-actions {
				justify-content: flex-end;
			}
		}
	}

	@media all and (max-width: 768px) {
		padding: 5px;

		.product {
			grid-template-columns: 1fr;
			grid-row-gap: 10px;
		}

		.product-actions {
			flex-wrap: wrap;

			& > .btn,
			.inp-btn {
				margin-bottom: 10px;
			}
		}

		.product-images {
			figure {
				max-width: 768px;
				max-height: auto;
				overflow: visible;
				width: 100%;

				img {
					display: block;
					margin: 0 auto;

				}
			}
		}
	}
}

.filters {
	flex-wrap: wrap;
}

.product-row {
	margin-bottom: 20px;

	.bulk-add-selector {
		width: 40px;

		input[type="checkbox"] {
			width: 21px;
			height: 21px;
			margin-top: 2px;
		}
	}

	.product {
		width: calc(100% - 40px);
	}

	@media all and (max-width: 768px) {
		position: relative;

		.bulk-add-selector {
			position: absolute;
			top: 10px;
			left: 10px;
		}

		.product {
			width: 100%;
		}
	}
}


.modal {
	.modal-dialog {
		width: 90%;
		max-width: 1000px;
		top: 50%;
		transform: translateY(-50%);
		margin: 0 auto;

		.modal-body {
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
}




.subhead {
	display: flex;
	justify-content: space-between;

	padding: 5px 0 15px 0;

	h2, h3 {
		padding: 0;
		margin: 0;
	}

	.input-group {
		width: auto;

		label {
			margin: 5px 5px 0 0;
		}

		.btn + .btn {
			margin-left: 10px;
		}
	}

	@media all and (max-width: 768px) {
		flex-wrap: wrap;

		h3 {
			width: 100%;
		}

		.input-group {
			width: 100%;
			justify-content: flex-end;
		}
	}
}


.form {
	width: 75%;
	max-width: 800px;
	padding: 20px 0;

	.input-group {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;

		&.closer {
			@media all and (min-width: 451px) {
				margin-bottom: 4px;
			}
		}

		label,
		input {
			align-self: center;
		}

		label {
			width: 200px;
			text-align: right;
			padding-right: 10px;
			margin-bottom: 0;
		}

		input {
			width: calc(100% - 200px);

			&[type="radio"],
			&[type="checkbox"] {
				width: 20px;
			}
		}

		.validation-error {
			color: red;
			padding-left: 200px;
			padding-bottom: 20px;
		}

		&[data-validate] {
			label:after {
				content: '*';
				color: red;
				padding-left: 5px;
			}
		}
	}

	@media all and (max-width: 768px) {
		width: 100%;

		.input-group {
			label {
				width: 160px;
			}

			input {
				width: calc(100% - 160px);
			}
		}
	}

	@media all and (max-width: 450px) {
		.input-group {
			label,
			input {
				width: 100%;
			}

			label {
				text-align: left;
				line-height: 1.1;
			}

			&.radio {
				input {
					order: 1;
				}

				label {
					order: 2;
					width: auto;
					padding-top: 5px;
				}
			}
		}
	}
}


.add-edit {
	position: relative;

	.form {
		max-width: none;
	}

	.actions {
		transition: all 400ms ease-in-out;
		position: absolute;
		top: 50%;
		left: calc(75% + 20px);
		transform: translateY(-50%);
		width: 25%;
	}


	&.has-many-add-one {
		overflow: hidden;

		.form {
			transform: translateY(-100%);
			transition: all 400ms ease-in-out;
		}

		.actions {
			left: 0;
		}

		&[data-adding] {
			.form {
				transform: translateY(0);
			}

			.actions {
				left: calc(75% + 20px);
			}
		}
	}
}


.filters,
.bulk-add {
	padding: 5px 15px;
	margin-bottom: 10px;
	border: 1px solid #bbb;

	h3 {
		margin-bottom: 0;
		color: #888;
	}
}

.filters {
	ul {
		
	}

	.filter-group,
	.filters-ui {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;
		column-gap: 10px;
		// border: 1px solid red;
		// overflow-x: scroll;

		li {
			& > label {
				display: block;
				margin-bottom: 20px;
			}

			&[data-inactive] {
				opacity: 0.25;
				z-index: -1;
			}
		}
	}

	.filter-group {

	}

	.filters-ui {
		li {
			// Okay. I know it's icky, but Chrome can't handle
			// flex AND the starting position calculation JS for
			// dragable slider elements.
			// 
			// Firefox totally can, btw.
			//
			// Actually, it looks like this was solved by a timeout
			// on mounted in Filters.vue
			// 
			// float: left;
			// margin-right: 10px;
			// margin-bottom: 10px;
		}
	}

	.filter-group-container,
	.filter-slider-container {
		border-radius: 5px;
		padding: 10px;
		border: 2px solid white;

		&.active {
			border: 2px solid $green;
		}
	}

	.filter-button-group {

	}

	// Slider Feature.
	// 
	// Removed 24-0201, per client request.
	.filter-slider {
		position: relative;
		min-width: 200px;
		min-height: 50px;

		.slider-line {
			position: absolute;
			width: 100%;
			left: 0;
			height: 5px;
			background: #ddd;

			&:before,
			&:after {
				content: '';
				width: 1px;
				height: 30px;
				position: absolute;
				top: -4px;
				background: #ddd;
				z-index: -1;
			}

			&:before {
				left: 0;
			}

			&:after {
				right: 0;
			}

			.slider-dot-min,
			.slider-dot-max {
				width: 30px;
				height: 30px;
				background-color: #222;
				border-radius: 15px;
				cursor: pointer;

				position: absolute;
				top: -13px;
			}

			.slider-dot-min {
				left: 0;
			}

			.slider-dot-max {
				right: 0;
			}
		}

		input[type="text"] {
			position: absolute;
			top: -40px;
			width: 50px;
			border: 3px solid $green;
			border-radius: 5px;
			left: -10px;
			text-align: center;
			padding: 5px 0 1px 0;
			opacity: 0;
			transition: all 200ms ease-in-out;

			&.active {
				opacity: 1;
			}
		}

		.lower-bound {
			left: 0;
		}

		.upper-bound {
			right: 0;
			text-align: right;
		}

		label {
			position: absolute;
			bottom: 0;
			margin: 0;

			&:first-child {
				left: 0;
			}

			&:last-child {
				right: 0;
			}
		}
	}

	.filter-range {
		// Replaces Slider feature.

		.range,
		.labels-container {
			display: grid;
			gap: 10px;
			grid-template-columns: 90px 90px 50px;
		}

		.range {

			[type="number"] {
				border: 2px solid $green;
				padding: 7px 5px 6px;
			}

			.submit-range {
				padding-left: 5px;
				padding-right: 5px;
				text-transform: uppercase;
			}
		}

		.labels-container {
			padding-top: 5px; 

			.label-max {
				text-align: right;
			}
		}
	}
}

.bulk-add {
	& > .btn,
	.inp-btn {
		margin-bottom: 3px;
	}
}

.address {
	margin-bottom: 0;

	p {
		line-height: 1.1;
		font-size: 0.9em;
		margin-bottom: 0;
	}
}


.contacts-grid,
.accounts-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 10px;
	grid-column-gap: 10px;

	.contact,
	.account {
		border: 1px solid $gray-light;
		padding: 10px;
	}

	@media all and (max-width: 1024px) {
		grid-row-gap: 2px;
		grid-column-gap: 2px;
	}

	@media all and (max-width: 900px) {
		grid-template-columns: 1fr 1fr;
	}

	@media all and (max-width: 620px) {
		grid-template-columns: 1fr;
	}
}

.contact-card,
.account-card {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-row-gap: 5px;
	grid-column-gap: 5px;
	grid-template-areas:
		"details details details actions";

	&[data-selected] {
		border: 3px solid $green;
		margin-top: -2px;
	}

	// &.order-card {
	// 	grid-template-columns: 1fr;
	// 	grid-template-areas: "details";

	// 	.account-contact,
	// 	.account-footer {
	// 		grid-column-end: span 1;
	// 	}
	// }

	.contact,
	.account {

		&-details {
			grid-area: details;

			p {
				margin-bottom: 0.25em;
				line-height: 1.1;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&-actions {
			grid-area: actions;
			justify-self: end;
		}

		&-contact {
			grid-column-end: span 4;
			border: 1px solid $gray-light;
			padding: 7px;

			p {
				margin: 0;
			}
		}

		&-no-contact {
			grid-column-end: span 4;
			padding: 7px;

			p {
				margin: 0;
				text-align: center;
			}
		}

		&-footer {
			grid-column-end: span 4;
		}
	}
}


.contact-finder,
.account-finder {
	.contacts-grid,
	.accounts-grid {
		grid-template-columns: repeat(6, 1fr);
	}

	.contact-card,
	.account-card {
		grid-template-columns: 1fr;
		grid-template-areas: "details";

		cursor: pointer;

		.account-actions,
		.account-contact,
		.account-no-contact,
		.account-footer {
			display: none;
		}
	}

	@media all and (max-width: 768px) {
		.contacts-grid,
		.accounts-grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media all and (max-width: 550px) {
		.contacts-grid,
		.accounts-grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media all and (max-width: 375px) {
		.contacts-grid,
		.accounts-grid {
			grid-template-columns: 1fr;

			.contact-card,
			.account-card,
			.account {
				padding: 4px 10px;
			}
		}
	}
}




.label-order-destination {

}


.login-page {
	display: flex;
	justify-content: space-around;

	.login-box {
		// max-width: 600px;
		width: 800px;

		h2 {
			text-align: center;
		}

		.btn-group {
			justify-content: center;
		}

		.form {
			width: 100%;
		}
	}

	.error {
		margin-top: 50px;
		text-align: center;
		font-size: 1.2em;
		color: $red;
	}
}



.order-detail {
	.subhead {
		flex-wrap: wrap;
		align-items: flex-start;
	}

	h2 {
		// width: 100%;

		small {
			display: block;
		}
	}
}










