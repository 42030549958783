@use 'sass:math';
/*!
 * "Bootscrap" (as you can see below) is a stripped
 * down version of Bootstrap. We don't include the
 * extra weight of unneeded components, in particular
 * the BS grid. Anything can be resurrected, however,
 * by uncommenting from below. Note: Several elements
 * are commented in _utilities.scss as well. 
 */

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";

@import "tables";
// @import "forms";
// @import "buttons";
@import "transitions";
// @import "dropdown";
// @import "button-group";
// @import "input-group";

@import "nav";

@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";

@import "alert";

// @import "list-group";
// @import "close";

@import "modal";
// @import "tooltip";
// @import "popover";

@import "utilities";
// @import "print";
