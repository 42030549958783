// Body
$body-bg: #fff;
$body-color: #222;

$green: #4c7d7a;
$green-light: #dbe5e4;
$gray-light: #f0f0f0;
$gray: #e4e5e4;
$gray-medium: #707070;
$gray-dark: #545857;

$red: red;
$gold: #FFB500;

$futura: 'FuturaStdBook', Arial, Helvetica, sans-serif;
$futura-bold: 'FuturaStdBold', Arial, Helvetica, sans-serif;
$egyptienne: 'URW_EgyptienneURWLig', Arial, Helvetica, serif;
$myterio: 'tt0321m', Times, serif;

$btn-font-weight: 400;


// Typography
$font-family-sans-serif: $egyptienne;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// BS Variables
$primary: $green;

$breadcrumb-bg: #fff;

$btn-font-family: $futura;
$btn-border-radius: 0;
$btn-padding-y: 9px;
$btn-padding-x: 25px;

$modal-content-border-radius: 0;

