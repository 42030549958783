.header {
	width: 100%;
	padding: 20px 0;

	display: grid;
	grid-template-columns: 200px 1fr;
	column-gap: 20px;
	row-gap: 10px;
	grid-template-areas: 
		"logo top-nav"
		"logo nav"
		"bottom bottom";

	.logo {
		display: block;
		grid-area: logo;

		position: relative;
		min-height: 110px;

		img {
			max-width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.primary-nav {
		display: block;

		grid-area: nav;
		justify-self: end;


		.nav-item {
			padding: 20px 30px 15px;
			text-align: center;
			display: inline-block;
			// position: relative;

			font-family: $futura;

			&:last-child {
				padding-right: 0;
				cursor: pointer;
			}

			span {
				display: inline-block;
				position: relative;

				&:before {
					content: '';
					width: 0;
					height: 3px;
					position: absolute;
					bottom: -8px;
					left: 50%;
					transform: translateX(-50%);
					background: $green;
					transition: all 300ms ease-in-out;
				}
			}

			&.active,
			&:hover {
				span {
					&:before {
						width: 100%;
					}
				}
			}

			@media all and (max-width: 600px) {
				padding: 15px 10px;

				&:last-child {
					padding-right: 0;
				}
			}

			
		}
	}

	.top-nav {
		grid-area: top-nav;
		justify-self: end;
	}

	.header-bottom {
		grid-area: bottom;
		position: relative;

		h1 {
			color: $green;
			padding: 10px 0 5px;
			margin: 0;

			span {
				display: inline-block;
				padding-top: 0.25em;
				font-family: $egyptienne;
			}
		}

		&:before,
		&:after {
			content: '';
			width: 101vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		&:before {
			height: 5px;
			background: $gray;
			z-index: 0;
		}

		&:after {
			height: 100%;
			background: $gray-light;
			z-index: -1;
		}
	}

	@media all and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"logo blank"
			"logo top-nav"
			"nav nav"
			"bottom bottom";

		.logo {
			min-height: 60px;

			img {
				position: static;
				transform: none;
				max-width: 200px;
			}
		}
	}

	@media all and (max-width: 440px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"logo logo"
			"top-nav top-nav"
			"nav nav"
			"bottom bottom";

		.primary-nav {
			.nav-item {
				padding: 15px 7px;
				font-size: 0.7rem;

				&:last-child {
					padding-right: 7px;
				}
			}
		}

		.logo {
			min-height: none;

			img {
				max-width: 175px;
			}
		}

		.top-nav {
			font-size: 0.7rem;
		}

		.header-bottom {
			h1 {
				font-size: 1.75rem;
			}
		}
	}
}

.footer {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	text-align: center;
	color: white;

	padding: 20px 0;

	&:before {
		content: '';
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		top: 0;
		z-index: -1;
		border-top: 4px solid $green;
		height: 100%;
		width: 101vw;
		background: $gray-dark;
	}

	p {
		margin-bottom: 0;
	}
}