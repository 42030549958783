@media screen, print {


// Reset/Normalize

img {
	max-width: 100%;
}

body {
	font-family: $egyptienne;
}


// Actual Styles

.label-sheet,
.sales-sheet {
	.pdf-wrapper {
		background: white;
		width: 8.5in;
		min-height: 11in;
		box-sizing: border-box;
		// padding: 20px 35px 43px 35px;
		padding: 0.375in 0.5in 0.75in 0.5in;
		page-break-after: always;

		&:last-child {
			page-break-after: avoid;
			// min-height: 10.9in;
			// height: 10.9in;
		}
	}

	.sales-sheet-body,
	.label-sheet-body {
		.product-info {
			p {
				margin: 0;
				line-height: 1.2;
				font-size: 16px;
			}

			p.alt-header {
				margin-top: 5px;
			}

			strong {
				font-family: $futura-bold;
			}
		}
	}
}

.print-pdf {
	padding: 0;
	margin: 0;
}

.sales-sheet {
	// padding: 40px 20px;

	&.preview {
		background: #222;
		overflow: scroll;

		.pdf-wrapper {
			margin: 0 auto;
		}
	}

	.cover-sheet-header {
		display: flex;
		justify-content: flex-end;

		.pdf-logo {
			max-width: 220px;
		}
	}

	.cover-sheet-body {
		padding: 20px 0;
		min-height: 792px;
		position: relative;

		.cover-sheet-content {
			background: white;
			width: 100%;
			height: 400px;
			margin-top: 100px;

			position: relative;

			box-shadow: 0 0 10px rgba(0,0,0,0.6);

			h2 {
				position: absolute;
				bottom: 0;
				transform: translateY(calc(50% + 7px));
				width: 400px;

				padding: 0;
				margin: 0 0 0 50px;

				font-family: $egyptienne;
				font-size: 45px;
				line-height: 60px;

				color: $gold;

				span {
					display: block;
					font-family: $futura-bold;
					color: #000;
				}
			}
		}

		img {
			position: absolute;
			top: 0;
			right: 0;
			width: 93%;
		}
	}

	.cover-sheet-footer {
		p {
			padding-top: 20px;
		}
	}

	.sales-sheet-header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 27px;
		border-bottom: 9px solid $gold;

		.pdf-logo {
			max-width: 240px;
		}

		.pdf-header {
			h2 {
				margin: 0;
				font-size: 20px;
				padding: 10px 0;
			}

			p {
				margin: 0;
				font-size: 17px;
				line-height: 1;
			}
		}
	}

	.sales-sheet-body {
		padding: 20px 0;
		min-height: 732px;

		li {
			display: flex;
			justify-content: space-between;
			list-style: none;
			padding-bottom: 21px;
			min-height: 200px;
			column-gap: 20px;
		}

		.product-image {
			min-width: 225px;
			max-width: 350px;
			max-height: 175px;

			img {
				// max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.cover-sheet-footer,
	.sales-sheet-footer {
		p {
			text-align: center;
		}

		p, ul {
			margin: 0;
		}

		strong {
			font-family: $futura-bold;
		}

		.footer-underline {
			width: 400px;
			height: 2px;
			display: block;
			background: $gray-medium;
			margin: 0 auto;
		}

		ul {
			display: block;
			padding: 5px 20px 15px 20px;
			text-align: center;

			li {
				list-style: none;
				position: relative;
				display: inline-block;

				p {
					padding: 5px 20px 1px 20px;
					border-right: 1px solid $gray-medium;
				}

				&:last-child {
					p {
						border-right: none;
					}
				}

				// &:before,
				// &:after {
				// 	content: '';
				// 	position: absolute;
				// 	background: $gray-medium;
				// }

				// &:before {
				// 	width: calc(100% + 31px);
				// 	height: 2px;
				// 	bottom: -10px;
				// 	left: 50%;
				// 	transform: translate(-50%);
				// }

				// &:after {
				// 	height: 100%;
				// 	width: 1px;
				// 	right: -15px;
				// 	top: 0;
				// }

				// &:last-child {
				// 	&:after {
				// 		display: none;
				// 	}
				// }
			}
		}
	}
}



.label-sheet {
	.pdf-wrapper {
		padding: 0 0.1875in;
	}

	.label-sheet-header {
		border-bottom: none;
		padding: 0;
		height: 36px;
	}

	.label-sheet-footer {
		padding: 0;
		height: 36px;
	}

	.label-sheet-footer,
	.label-sheet-header {
		p {
			margin: 0;
			padding-top: 7px;
			padding-left: 20px;
			font-size: 12px;
		}
	}

	.label-sheet-body {
		display: grid;
		grid-template-columns: 4in 4in;
		grid-column-gap: 8px;

		li {
			position: relative;

			// &:nth-child(2n) {
			// 	&:before {
			// 		content: '';
			// 		width: 729px;
			// 	}
			// }

			.product-info {
				height: 2in;
				padding-left: 10px;
				// overflow: hidden;
				position: relative;

				// &:before {
				// 	content: '';
				// 	width: 100%;
				// 	height: 100%;
				// 	border: 1px solid blue;
				// 	position: absolute;
				// }

				.cr {
					background: #444;
					width: 1px;
					height: 1px;
					position: absolute;
				}

				.cr-tr,
				.cr-tl,
				.cr-br,
				.cr-bl {
					width: 1px;
					height: 15px;
				}

				.cr-r,
				.cr-l,
				.cr-dr,
				.cr-dl {
					width: 4px;
					height: 1px;
				}

				.cr-tr {
					top: -20px;
					right: 0;
				}

				.cr-tl {
					top: -20px;
					left: 0;
				}

				.cr-br {
					bottom: -20px;
					right: 0;
				}

				.cr-bl {
					bottom: -20px;
					left: 0;
				}

				.cr-r {
					top: 0;
					right: -6px;
				}

				.cr-l {
					top: 0;
					left: -6px;
				}

				.cr-dr {
					bottom: 0;
					right: -6px
				}

				.cr-dl {
					bottom: 0;
					left: -6px;
				}

				.label-logo {
					padding: 8px 0 12px 0;
					width: 125px;
				}
			}

			p {
				margin: 0;
				line-height: 1.2;
				font-size: 14px;
			}

			&:nth-child(2n + 1) {
				.product-info {
					.cr-dl,
					.cr-l {
						left: -12px;
						width: 8px;
					}
				}
			}

			&:nth-child(2n) {
				.product-info {
					.cr-dr,
					.cr-r {
						right: -12px;
						width: 8px;
					}
				}
			}
		}
	}
}









} // /@media print
