@font-face {
    font-family: 'URW_EgyptienneURWLig';
    src: url('/fonts/URW_EgyptienneURWLig.eot');
    src: url('/fonts/URW_EgyptienneURWLig.eot?#iefix') format('embedded-opentype'),
         url('/fonts/URW_EgyptienneURWLig.woff2') format('woff2'),
         url('/fonts/URW_EgyptienneURWLig.woff') format('woff'),
         url('/fonts/URW_EgyptienneURWLig.ttf') format('truetype'),
         url('/fonts/URW_EgyptienneURWLig.svg#URW_EgyptienneURWLig') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tt0321m';
    src: url('/fonts/tt0321m.eot');
    src: url('/fonts/tt0321m.eot?#iefix') format('embedded-opentype'),
         url('/fonts/tt0321m.woff2') format('woff2'),
         url('/fonts/tt0321m.woff') format('woff'),
         url('/fonts/tt0321m.ttf') format('truetype'),
         url('/fonts/tt0321m.svg#tt0321m') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tt0324m';
    src: url('/fonts/tt0324m.eot');
    src: url('/fonts/tt0324m.eot?#iefix') format('embedded-opentype'),
         url('/fonts/tt0324m.woff2') format('woff2'),
         url('/fonts/tt0324m.woff') format('woff'),
         url('/fonts/tt0324m.ttf') format('truetype'),
         url('/fonts/tt0324m.svg#tt0324m') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStdBold';
    src: url('/fonts/FuturaStdBold.eot');
    src: url('/fonts/FuturaStdBold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/FuturaStdBold.woff2') format('woff2'),
         url('/fonts/FuturaStdBold.woff') format('woff'),
         url('/fonts/FuturaStdBold.ttf') format('truetype'),
         url('/fonts/FuturaStdBold.svg#FuturaStdBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStdBook';
    src: url('/fonts/FuturaStdBook.eot');
    src: url('/fonts/FuturaStdBook.eot?#iefix') format('embedded-opentype'),
         url('/fonts/FuturaStdBook.woff2') format('woff2'),
         url('/fonts/FuturaStdBook.woff') format('woff'),
         url('/fonts/FuturaStdBook.ttf') format('truetype'),
         url('/fonts/FuturaStdBook.svg#FuturaStdBook') format('svg');
    font-weight: normal;
    font-style: normal;
}
