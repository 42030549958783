table {
	tr {
		border: 1px solid #ddd;

		td, th {
			padding: 5px 10px 2px 10px;
		}

		td {
			&.flex {
				display: flex;
			}

			&.top {
				vertical-align: top;
			}
		}

		.row-extra {
			position: absolute;
			bottom: 0;
			left: 0;

			display: flex;
			align-items: center;

			input[type="text"] {
				width: 100%;
			}

			input, .btn {
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&.has-extra {
			position: relative;

			td {
				padding-bottom: 100px;
			}

			.row-extra {
				width: 100%;
				height: 100px;
				padding: 10px;
			}
		}

		&.orders {
			.btn-grp {
				.btn {
					margin-bottom: 10px;
					margin-right: 5px;
				}
			}
		}
	}

	&.stripe {
		tr td {
			background-color: $green-light;
		}

		tr:nth-child(2n + 1) {
			td {
				background-color: $gray-light;
			}
		}
	}

	.product & {
		width: 100%;
		margin-bottom: 20px;

		td:first-child {
			font-family: $futura-bold;
		}
	}

	.cart-page &,
	.orders-page & {
		width: 100%;

		img {
			max-width: 90px;
		}
	}

	p {
		margin-bottom: 0;
	}
}

.table-wrapper {
	&.pdf-cart {
		background-color: #fff;

		th:nth-child(4),
		td:nth-child(4) {
			min-width: 260px;
		}
	}
}



@media all and (max-width: 1024px) {
	.table-wrapper {
		overflow-x: scroll;

		table {
			min-width: 1000px;
		}

		&.carts {
			table {
				min-width: 800px;
			}
		}

		&.pdf-cart {
			background-color: #fff;

			th:nth-child(4),
			td:nth-child(4) {
				min-width: 300px;
			}

			th:nth-child(3),
			td:nth-child(3) {
				min-width: 300px;
			}
		}

		&.order-history {
			table {
				min-width: 500px;
			}
		}

		.min-175 {
			min-width: 175px;
		}

		.min-200 {
			min-width: 200px;
		}
	}
}




h2 {
	padding-top: 10px;
	padding-bottom: 10px;
}

h3 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.noresults {
	padding: 40px;
	text-align: center;
}

img {
	max-width: 100%;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a,
button {
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
}

h1, h2, h3, 
h4, h5, h6 {
	font-family: $futura-bold;
}

p {
	margin-bottom: 0.5em;
}

strong {

	&.highlight {
		background: yellow;
		color: black;
		font-weight: bold;
		padding: 0.4em 0 0.2em 0;
	}
}


.app {

}


.video-container {
	margin-bottom: 40px;

	video {
		max-width: 100%;
	}
}



.tab-nav {
	padding: 0 10px;

	.tab {
		cursor: pointer;

		color: $gray-medium;
		padding: 5px 15px;
		display: inline-block;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-left: 1px solid $gray;
		border-top: 1px solid $gray;
		border-right: 1px solid $gray;
		border-bottom: 1px solid $gray-medium;
		background: white;
		text-transform: none;

		&.active {
			border-bottom: 1px solid white;
			color: $body-color;
			border-left-color: $gray-medium;
			border-top-color: $gray-medium;
			border-right-color: $gray-medium;
		}
	}
}


@media all and (max-width: 768px) {
	.tab-nav {
		.tab {
			border-radius: 0;
			border: 3px solid $gray-medium;
			padding: 10px 30px;
			margin: 0 10px 10px 0;

			&.active {
				color: white;
				background: $green;
				border: 3px solid $green;
			}
		}
	}

	.tab-body:before {
		display: none;
	}
}

.tab-body {
	padding: 40px 0;

	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 1px;
		background: $gray-medium;
		position: absolute;
		top: -1px;
		z-index: -1;
	}
}


// Search UI
.search {
	a {
		cursor: pointer;
	}

	.search-input {
		display: flex;
		width: 100%;
		max-width: 600px;

		position: relative;

		&.wide {
			max-width: 700px;

			.btn {
				width: 180px;
			}

			.clear {
				right: 190px;
			}
		}

		.inp {
			flex-grow: 1;

			border-right: none;
		}

		.clear {
			position: absolute;
			right: 120px;
			top: 4px;
			font-size: 26px;
			padding: 10px 5px 0;
			line-height: 20px;
		}

		.btn {
			width: 110px;
		}
	}

	

	.search-autocomplete {
		display: none;

		width: calc(100% - 32px);
		max-width: 600px;

		position: absolute;
		z-index: 100;

		padding: 10px 20px 0 20px;
		border: 1px solid $green;
		border-top: none;
		background: white;

		

		.notice {
			display: block;
			padding: 5px 20px;
			text-align: center;
		}
	}

	.search-input.wide + .search-autocomplete {
		max-width: 700px;
	}

	.search-input .inp:focus + .search-autocomplete {
		display: block;
	}
}


// Kinda like Bootstrap's buttons, but with 
// extraneous crap removed.
// 
.inp {
	background-color: white;
	color: $body-color;
	padding: 7px 10px 5px;
	transition: all 300ms ease-in-out;
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	vertical-align: middle;
	// user-select: none;
	border: 2px solid $green;
	border-radius: 0;

	&:hover,
	&:focus,
	&:focus-visible,
	&:focus-within,
	&:active {
		// box-shadow: none;
		outline: none;
		border-color: darken($green, 10%);
	}
}

.btn-group {
	display: flex;
	flex-wrap: wrap;

	.btn {
		margin-right: 10px;
		margin-bottom: 10px;

		&:last-child {
			margin-right: 0;
		}
	}
}


.btn {
	background-color: $green;
	color: white;
	padding: 9px 25px 7px;
	transition: background 300ms ease-in-out;
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	text-align: center;
	vertical-align: middle;
	// user-select: none;
	border: none;
	cursor: pointer;

	// &.with-number {
	// 	padding-left: 9px;
	// }

	// &[type="number"] {
	// 	width: 45px;
	// 	padding: 9px 5px 7px;
	// }

	&:hover,
	&:focus,
	&:active {
		background-color: darken($green, 10%);
		color: white;
	}

	&[disabled] {
		opacity: 0.6;
		background-color: $green;
	}

	&.btn-outline {
		background-color: white;
		border: 2px solid $green;
		color: $green;

		&:hover,
		&:focus,
		&:active {
			color: darken($green, 10%);
			border-color: darken($green, 10%);
			background-color: #ddd;
		}

		&.active {
			background-color: $green;
			color: white;
		}
	}

	&.btn-secondary {
		background-color: $gray-medium;

		&:hover,
		&:focus,
		&:active {
			background-color: darken($gray-medium, 10%);
			color: white;
		}

		&[disabled] {
			background-color: $gray-medium;
		}
	}

	&.btn-small {
		padding: 4px 11px 2px;
		border-radius: 0.5em;
		font-size: 0.75em;
	}

	@media all and (max-width: 768px) {
		padding: 9px 15px 7px;
	}
}

.inp-btn {
	display: inline-flex;

	.btn {
		&.with-number {
			padding-left: 9px;
		}

		&[type="number"] {
			width: 45px;
			padding: 9px 5px 7px;
		}
	}

	&:hover,
	&:focus,
	&:active {
		.btn {
			background-color: darken($green, 10%);
			color: white;
		}
	}
}



