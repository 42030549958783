
// Extension of BS modal
.modal {
	transition: all 1000ms ease-in-out;

	&.modal-open {
		display: block;
	}

	.modal-close {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 32px;
		line-height: 26px;
		padding: 5px 14px 12px;
	}

	.modal-header {
		padding-right: 50px;
	}

	.modal-content {
		max-height: calc(100vh - 2.5rem);
		overflow: scroll;
	}
}

.backdrop {
	content: '';
	width: 100%;
	position: fixed;
	transition: background 400ms ease-in-out;

	background: rgba(0,0,0,0);
	display: block;
	height: 0%;
	top: -1px;
	left: 0;

	&.active {
		background: rgba(0,0,0,0.5);
		top: 0;
		height: 100%;
	}
}

@keyframes timer {
	0% {
		width: 0;
	}
	80% {
		width: 100%;
	}
	100% {
		width: 100%;
	}
}


.notification {
	position: fixed;
	top: 10px;
	right: 10px;
	max-width: 400px;
	background: rgba($green, 90%);
	padding: 20px 40px 20px 15px;
	transition: all 300ms ease-in-out;
	transform: translateX(calc(100% + 20px));
	border-radius: 5px;
	overflow: hidden;

	.notification-close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 9px 13px 15px;
		line-height: 16px;
		font-size: 23px;

		&.btn,
		&.btn:hover {
			background-color: transparent;
		}
	}

	.messages {
		color: white;
		font-weight: bold;
		margin: 0;
		padding: 0 0 0 15px;
		list-style: none;

		p {
			margin: 0;
		}

		&.multiple {
			max-height: 360px;
			overflow: scroll;

			li {
				position: relative;

				&:before {
					content: '';
					width: 5px;
					height: 5px;
					border-radius: 5px;
					background: white;

					position: absolute;
					left: -14px;
					top: 7px;
				}
			}
		}
	}

	.notification-timer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8px;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: darken($green, 5%);
		}
	}

	&.active {
		transform: translateX(0);

		.notification-timer:before {
			animation: timer 10s linear;
		}
	}
}
















