// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'fonts';
@import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import 'bootscrap/bootstrap.scss';

// Stryker Theme
@import 'partials/modals';
@import 'partials/header-nav';
@import 'partials/layout';
@import 'partials/pdfs';

@import 'partials/theme';

